"use client";

import { Button, Code, Heading, Stack, Text } from "@chakra-ui/react";
import type { Metadata } from "next";
import { FiRefreshCw } from "react-icons/fi";

export const metadata: Metadata = {
  title: "Error | Nest",
};

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest: string };
  reset: () => void;
}) {
  return (
    <Stack mt="40" spacing="4" align="center">
      <Heading lineHeight="1.2" fontSize="4xl">
        Error
      </Heading>
      <Text fontSize="xl" maxW="xl" align="center">
        It's not a bug, it's a feature! 🤡
      </Text>
      <Code borderRadius="md" p="4" colorScheme="red" fontSize="xl" maxW="xl">
        {error.message}
      </Code>
      <Button variant="secondary" onClick={reset} leftIcon={<FiRefreshCw />}>
        Reset
      </Button>
    </Stack>
  );
}
